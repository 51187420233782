import { signOut, useSession } from 'next-auth/react';
import { useEffect, useMemo } from 'react';
import { NextAuthErrors } from '../utils/constants';
import routes from '../utils/routes';

export const useIsAuthenticated = () => {
  const { status, data } = useSession();
  const isAuthenticated = useMemo(() => status === 'authenticated', [status, data?.error]);

  useEffect(() => {
    if (data && data.error === NextAuthErrors.RefreshErr && isAuthenticated) {
      signOut({ redirect: false, callbackUrl: routes.login });
    }
  }, [data?.error, isAuthenticated]);

  return {
    isAuthenticated,
  };
};
