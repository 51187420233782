import { Navbar } from 'reactstrap';
import { CSSProperties, ReactNode } from 'react';
import Logo from '../images/Logo';
import LanguageSwitcher from '@/app/components/navigation/LanguageSwitcher';
import styles from './Header.module.scss';
import cn from 'classnames';

type Props = {
  navMenuButton?: ReactNode;
  isAuthenticated: boolean;
};

export default function Header(props: Props) {
  const { navMenuButton, isAuthenticated } = props;

  const style: CSSProperties = {
    marginRight: isAuthenticated ? '36px' : '0px',
  };

  return (
    <Navbar
      dark
      full={true}
      container="fluid"
      className={cn(styles.navbar, 'position-fixed w-100 top-0 z-3')}
    >
      <div className="d-flex w-100 justify-content-between align-items-start">
        {navMenuButton}
        <div className="flex-grow-1" style={style}>
          <Logo />
        </div>
        <LanguageSwitcher />
      </div>
    </Navbar>
  );
}
